import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import PromoItemDetail from './PromoItemDetail';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {PROMO_TYPE, PROMO_HINT} from '../../dictionary';
import Hint from '../../Widgets/Hint';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import Row from '../../Widgets/Row';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import {Add} from '@styled-icons/material/Add';
import Anchor from '../../Widgets/Anchor';
import BackButton from '../../Components/BackButton';
const appConfig = require('../../data.json');

let typeOptions = Object.keys(PROMO_TYPE).map((key) => ({
  label: key,
  value: key,
}));

export const UISTATE = {
  NORMAL_LIST: 0,
  MANUAL_LIST: 1,
  DETAIL: 2,
};

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function PromoItemListPage(props) {
  const [records, setRecords] = useState([]);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({
    ...PAGINATION_INIT,
    type: typeOptions[0].value,
  });
  const [selected, setSelected] = useState();
  const [uiState, setUiState] = useState(UISTATE.NORMAL_LIST);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');

  const getRecords = useCallback(async () => {
    let {current, pageSize, type} = filters;
    setLoading(true);

    let params = {
      offset: (current - 1) * pageSize,
      limit: pageSize,
    };

    try {
      let resp = await actions.getPromoItems(params);
      resp = resp.filter((p) => p.type === type);
      setRecords(resp);
    } catch (ex) {
      console.warn(ex);
    }
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const remove = useCallback(async (id) => {
    setLoading(true);
    try {
      await actions.deletePromoItem(id);
      await getRecords();
      Ant.message.success('刪除成功');
    } catch (ex) {
      console.warn(ex);
      Ant.message.error('刪除發生錯誤');
    }
    setLoading(false);
  }, []);

  const changeFilters = (obj) => setFilters((prev) => ({...prev, ...obj}));

  if (uiState === UISTATE.DETAIL) {
    return (
      <Wrapper>
        <Row style={{marginBottom: 20}}>
          <BackButton onClick={() => setUiState(UISTATE.NORMAL_LIST)}>
            返回
          </BackButton>
        </Row>
        <PromoItemDetail
          id={selected && selected.id}
          defaultType={filters.type}
          onUpdate={getRecords}
          onClose={() => setUiState(UISTATE.NORMAL_LIST)}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Block style={{marginBottom: 20}}>
          <div>
            <div className="row">
              <h4>圖片類型</h4>
              {Object.keys(PROMO_TYPE).map((t) => (
                <Button
                  key={t}
                  type={filters.type === t ? 'primary' : 'default'}
                  onClick={() => changeFilters({type: t})}
                  style={{marginRight: 10}}>
                  {PROMO_TYPE[t]}
                </Button>
              ))}
              <Hint type="info">
                {`${filters.type} : ` + PROMO_HINT[filters.type]}
              </Hint>
              <div style={{flex: 1}} />
              <Button
                type="primary"
                onClick={getRecords}
                icon={
                  <Refresh color="#fff" size={20} style={{marginRight: 5}} />
                }>
                刷新
              </Button>
            </div>
          </div>
        </Block>

        <Block>
          <div className="row">
            <h2>{PROMO_TYPE[filters.type]}</h2>
            <div style={{flex: 1}} />
            <Button
              onClick={() => {
                setSelected(null);
                setUiState(UISTATE.DETAIL);
              }}
              type="primary"
              color={appConfig.colors.sub}>
              <Add color="#fff" size={20} style={{marginRight: 5}} />
              新增
            </Button>
          </div>
          <div>
            <Ant.Table
              loading={loading}
              columns={[
                {
                  title: '編號',
                  render: (_, record) => (
                    <Anchor
                      type="button"
                      onClick={() => {
                        setSelected(record);
                        setUiState(UISTATE.DETAIL);
                      }}>
                      {record.id}
                    </Anchor>
                  ),
                  width: 50,
                  fixed: 'left',
                },
                {
                  title: '顯示名稱',
                  dataIndex: 'label',
                  width: 100,
                },
                {
                  title: '名稱',
                  dataIndex: 'name',
                  width: 100,
                },
                {
                  title: '分類',
                  dataIndex: 'type',
                  width: 100,
                },
                {
                  title: '',
                  width: 100,
                  render: (record) => (
                    <Button
                      onClick={() => {
                        if (confirm('確定要刪除？')) {
                          remove(record.id);
                        }
                      }}>
                      刪除
                    </Button>
                  ),
                },
              ]}
              dataSource={records}
              rowKey={(item) => item.id}
              pagination={{
                pageSize: filters.pageSize,
                current: filters.current,
                total,
              }}
              onChange={(pagination) =>
                changeFilters({
                  pageSize: pagination.pageSize,
                  current: pagination.current,
                })
              }
            />
          </div>
        </Block>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
