import React, {useState, useCallback, useEffect} from 'react';
import * as Ant from 'antd';
import ImageEditor from '../../Components/ImageEditor';
import {PROMO_TYPE} from '../../dictionary';
import {ErrPromoItem, errorHandler} from '../../errors';
import {useOutlet} from 'reconnect.js';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';

const typeOptions = Object.keys(PROMO_TYPE);

const UISTATE = {
  create: 0,
  edit: 1,
};

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default function PromoItemDetail(props) {
  const {id, defaultType, onUpdate, onClose} = props;
  const [uiState] = useState(id ? UISTATE.edit : UISTATE.create);
  const [loading, setLoading] = useOutlet('loading');
  const [record, _setRecord] = useState({
    name: '',
    label: '',
    type: defaultType || typeOptions[0],
    link: '',
    priority: '',
    image: '',
  });
  const [actions] = useOutlet('actions');

  const setRecord = useCallback(
    (obj = {}) => _setRecord((prev) => ({...prev, ...obj})),
    [],
  );

  const getRecord = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        let resp = await actions.getPromoItem(id);
        setRecord(resp);
      } catch (ex) {
        console.warn(ex);
        Ant.message.error('取得詳細錯誤');
      }
      setLoading(false);
    }
  }, [id]);

  const valid = () => {
    const {name, image} = record;
    if (uiState === UISTATE.create) {
      if (!image) {
        throw new ErrPromoItem('必須上傳圖片');
      }
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      valid();

      if (uiState === UISTATE.create) {
        await actions.addPromoItem(record);
        Ant.message.success('新增成功');
      } else {
        await actions.editPromoItem({
          ...record,
          priority: Number.isInteger(record.priority)
            ? record.priority
            : undefined,
        });
        Ant.message.success('編輯成功');
      }

      onUpdate();
      onClose();
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  if (!record) {
    return null;
  }

  return (
    <Ant.Spin spinning={loading}>
      <Block>
        <h2>{uiState === UISTATE.create ? '新增' : '#' + record.id}</h2>
        <div className="divider" />
        <div>
          <div className="row">
            <h4>編號</h4>
            <div>{record.id}</div>
          </div>
          <div className="row">
            <h4>名稱</h4>
            <Ant.Input
              value={record.name}
              onChange={(e) => setRecord({name: e.target.value})}
            />
          </div>
          <div className="row">
            <h4>前台顯示名稱</h4>
            <Ant.Input
              value={record.label}
              onChange={(e) => setRecord({label: e.target.value})}
            />
          </div>
          <div className="row">
            <h4>分類</h4>
            <Ant.Select
              value={record.type}
              onChange={(value) => setRecord({type: value})}>
              {typeOptions.map((p) => (
                <Ant.Select.Option key={p} value={p}>
                  {PROMO_TYPE[p]}
                </Ant.Select.Option>
              ))}
            </Ant.Select>
          </div>
          <div className="row">
            <h4>連結</h4>
            <Ant.Input
              value={record.link}
              onChange={(e) => setRecord({link: e.target.value})}
            />
          </div>

          <div className="row">
            <h4 style={{alignSelf: 'flex-start'}}>圖片</h4>
            <ImageEditor
              images={[{image: record.imageBase64 || record.image, name: ''}]}
              name=""
              onlyFile={true}
              onCreate={
                record.image
                  ? null
                  : async ({file}) => {
                      setLoading(true);
                      let base64 = await getBase64(file); //preview img
                      setLoading(false);
                      setRecord({imageBase64: base64, image: file});
                    }
              }
              onEdit={async ({file}) => {
                setLoading(true);
                let base64 = await getBase64(file); //preview img
                setLoading(false);
                setRecord({imageBase64: base64, image: file});
              }}
              onDelete={({file}) =>
                setRecord({
                  image: null,
                  imageBase64: null,
                })
              }
              sortable={true}
            />
          </div>

          <div className="row">
            <h4>優先度</h4>
            <Ant.Input
              type="number"
              value={record.priority}
              onChange={(e) => setRecord({priority: parseInt(e.target.value)})}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="row" style={{justifyContent: 'flex-end', margin: 0}}>
          <Button type="primary" onClick={submit}>
            儲存
          </Button>
        </div>
      </Block>
    </Ant.Spin>
  );
}
